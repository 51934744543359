import React, {Component} from 'react';
import {Container, Divider, Grid, Icon, Item, Segment} from "semantic-ui-react";
import {Link} from "../../helpers/router";
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

class NewFooter extends Component {
  render() {
    return (
      <Segment className="new-footer" basic>
        <Helmet>
          <script type="application/ld+json">
            {`
                {
                  "@context": "https://schema.org",
                  "@type": "Store",
                  "url": "https://pure4me.pl",
                  "name": "pure4me",
                  "email": "sklep@pure4me.pl",
                  "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Nocznickiego 31",
                    "addressLocality": "Warszawa",
                    "addressRegion": "Mazowieckie",
                    "postalCode": "01-918",
                    "addressCountry": "PL"
                  }
                }
              `}
          </script>
        </Helmet>
        <Container>
          <Grid columns={6} centered stackable>
            <Grid.Row>
              <Grid.Column width={2}>
                <Link to="/">
                  <StaticImage src='../../img/logo_color.png' alt="pure4me" placeholder='none' className='footer-logo' style={{margin: "0 auto"}}/>
                </Link>
              </Grid.Column>
              <Grid.Column width={3}>
                  <p>Natur Produkt Zdrovit sp.&nbsp;z&nbsp;o.o.<br/>
                    Ul. Nocznickiego 31<br/>
                    01-918 Warszawa</p>
                    <p>NIP 118-00-09-700<br/>
                    REGON&nbsp;012092323<br/>
                    KRS&nbsp;80429</p>
              </Grid.Column>
              <Grid.Column width={3}>
                <p>
                <Item as="a" href="https://www.instagram.com/pure4me" target="_blank" rel="noreferrer">
                  <Icon name='instagram' circular inverted size='large' id="icon-social"/>
                </Item>
                <Item as="a" href="https://www.facebook.com/pure4me.suplementy" target="_blank" rel="noreferrer">
                  <Icon name='facebook' circular inverted size='large' id="icon-social"/>
                </Item>
                </p>
                <a href="mailto:sklep@pure4me.pl">sklep@pure4me.pl</a><br />
                <Link to={"/policy"}>polityka prywatności</Link><br/>
                <Link to={"/regulations"}>regulamin</Link>
              </Grid.Column>
              <Grid.Column width={8}>
                <Grid columns={3}>
                  <Grid.Column>
                    <Link to="/suplementy-dla-mezczyzn">Suplementy dla mężczyzn</Link><br />
                    <Link to="/suplementy-na-wlosy">Suplementy na włosy i paznokcie</Link><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link to="/suplementy-przy-dietach">Suplementy przy dietach</Link><br />
                    <Link to="/suplementy-dla-sportowcow">Suplementy dla sportowców</Link><br />
                  </Grid.Column>
                  <Grid.Column>
                    <Link to="/suplementy-na-pamiec">Suplementy na pamięć i koncentrację</Link>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    );
  }
}

export default NewFooter;
