import * as React from "react";
import { StaticImage } from 'gatsby-plugin-image';

const listIcon = '../../img/newintro/tick.png';

const WIDTH = 50;

const IntroListItem = ({children, padded = false}) => (
  <div style={{display: 'flex', alignItems: 'center', padding: padded ? '10px 0' : '2px 0'}}>
    <div>
      <StaticImage src={listIcon} alt='Ikona' width={WIDTH} style={{width: WIDTH}} />
    </div>
    <div>
      {children}
    </div>
  </div>
)

export default IntroListItem;